<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <analytics-statistics-card></analytics-statistics-card>
    </v-col>
    <v-row class="match-height">
      <v-col
        cols="12"
        md="4"
      >
        <pie-chart-dashboard
          series-name="singlePharmacySingleMoleculePieChartSalesMfr"
        ></pie-chart-dashboard>
      </v-col>

      <v-col
        cols="12"
        md="8"
      >
        <stacked-time-seriesmonthly series="monthlyStackedSalesByMfr"></stacked-time-seriesmonthly>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>

export default {
    name: 'SingleMoleculeDashboard',
    components: {
        stackedTimeSeriesmonthly: () => import('@/views/molecule/stackedTimeSeriesmonthly.vue'),
        pieChartDashboard: () => import('@/components/charts/pieChartDashboard.vue'),
        AnalyticsStatisticsCard: () => import('@/views/molecule/AnalyticsStatisticsCard.vue'),
    },
    setup() {
        return {

        }
    }
}
</script>
